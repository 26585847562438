/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  overflow: auto;
}

.card {
  background-color: #fff;
  color: #333;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 10px;
}

.btn {
  display: inline-block;
  padding: 10px 30px;
  cursor: pointer;
  background: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 5px;
}

.btn-outline {
  background-color: transparent;
  border: 1px #fff solid;
}

.btn:hover {
  transform: scale(0.98);
}

/* Backgrounds & colored buttons */
.bg-primary,
.btn-primary {
  background-color: var(--primary-color);
  color: #fff;
}

.bg-secondary,
.btn-secondary {
  background-color: var(--secondary-color);
  color: #fff;
}

.bg-dark,
.btn-dark {
  background-color: var(--dark-color);
  color: #fff;
}

.bg-light,
.btn-light {
  background-color: var(--light-color);
  color: #333;
}

.bg-primary a,
.btn-primary a,
.bg-secondary a,
.btn-secondary a,
.bg-dark a,
.btn-dark a {
  color: #fff;
}

/* Text colors */
.text-primary {
  color: var(--primary-color);
}

.text-secondary {
  color: var(--secondary-color);
}

.text-dark {
  color: var(--dark-color);
}

.text-light {
  color: var(--light-color);
}

/* Text sizes */
.lead {
  font-size: 20px;
}

.sm {
  font-size: 1rem;
}

.md {
  font-size: 2rem;
}

.lg {
  font-size: 3rem;
}

.xl {
  font-size: 4rem;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

/* Alert */
.alert {
  background-color: var(--light-color);
  padding: 10px 20px;
  font-weight: bold;
  margin: 15px 0;
}

.alert i {
  margin-right: 10px;
}

.alert-success {
  background-color: var(--success-color);
  color: #fff;
}

.alert-error {
  background-color: var(--error-color);
  color: #fff;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.grid-3 {
  grid-template-columns: repeat(3, 1fr);
}

.inline-block {
  display: inline-block;
}

/* Margin */
.my-1 {
  margin: 1rem 0 !important;
}

.my-2 {
  margin: 1.5rem 0 !important;
}

.my-3 {
  margin: 2rem 0 !important;
}

.my-4 {
  margin: 3rem 0 !important;
}

.my-5 {
  margin: 4rem 0 !important;
}

.mx-1 {
  margin: 0 1rem !important;
}

.mx-2 {
  margin: 0 1.5rem !important;
}

.mx-3 {
  margin: 0 2rem !important;
}

.mx-4 {
  margin: 0 3rem !important;
}

.mx-5 {
  margin: 0 4rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mr-2 {
  margin-right: 1.5rem !important;
}

.mr-3 {
  margin-right: 2rem !important;
}

.mr-4 {
  margin-right: 3rem !important;
}

.mr-5 {
  margin-right: 4rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.ml-2 {
  margin-left: 1.5rem !important;
}

.ml-3 {
  margin-left: 2rem !important;
}

.ml-4 {
  margin-left: 3rem !important;
}

.ml-5 {
  margin-left: 4rem !important;
}

.m-1 {
  margin: 1rem !important;
}

.m-2 {
  margin: 1.5rem !important;
}

.m-3 {
  margin: 2rem !important;
}

.m-4 {
  margin: 3rem !important;
}

.m-5 {
  margin: 4rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 1.5rem !important;
}

.mb-3 {
  margin-bottom: 2rem !important;
}

.mb-4 {
  margin-bottom: 3rem !important;
}

.mb-5 {
  margin-bottom: 4rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-2 {
  margin-top: 1.5rem !important;
}

.mt-3 {
  margin-top: 2rem !important;
}

.mt-4 {
  margin-top: 3rem !important;
}

.mt-5 {
  margin-top: 4rem !important;
}

/* Padding */
.py-1 {
  padding: 1rem 0 !important;
}

.py-2 {
  padding: 1.5rem 0 !important;
}

.py-3 {
  padding: 2rem 0 !important;
}

.py-4 {
  padding: 3rem 0 !important;
}

.py-5 {
  padding: 4rem 0 !important;
}

.px-1 {
  padding: 0 1rem !important;
}

.px-2 {
  padding: 0 1.5rem !important;
}

.px-3 {
  padding: 0 2rem !important;
}

.px-4 {
  padding: 0 3rem !important;
}

.px-5 {
  padding: 0 4rem !important;
}

.p-1 {
  padding: 1rem !important;
}

.p-2 {
  padding: 1.5rem !important;
}

.p-3 {
  padding: 2rem !important;
}

.p-4 {
  padding: 3rem !important;
}

.p-5 {
  padding: 4rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.pb-2 {
  padding-bottom: 1.5rem !important;
}

.pb-3 {
  padding-bottom: 2rem !important;
}

.pb-4 {
  padding-bottom: 3rem !important;
}

.pb-5 {
  padding-bottom: 4rem !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pt-2 {
  padding-top: 1.5rem !important;
}

.pt-3 {
  padding-top: 2rem !important;
}

.pt-4 {
  padding-top: 3rem !important;
}

.pt-5 {
  padding-top: 4rem !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.pr-2 {
  padding-right: 1.5rem !important;
}

.pr-3 {
  padding-right: 2rem !important;
}

.pr-4 {
  padding-right: 3rem !important;
}

.pr-5 {
  padding-right: 4rem !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.pl-2 {
  padding-left: 1.5rem !important;
}

.pl-3 {
  padding-left: 2rem !important;
}

.pl-4 {
  padding-left: 3rem !important;
}

.pl-5 {
  padding-left: 4rem !important;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

.bolder {
  font-weight: 600;
}
