@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --primary-color: #047aed;
  --secondary-color: #1c3fa8;
  --dark-color: #002240;
  --light-color: #f4f4f4;
  --success-color: #5cb85c;
  --error-color: #d9534f;
  --blue-gray: #738fa7;
  --midnight-blue: #0c4160;
  --dark-blue: #071330;
  --navy-blue: #05445e;
  --blue-grotto: #189ab4;
  --blue-green: #75e6da;
  --baby-blue: #d4f1f4;
  --desert-sun: #d89c60;
  --burnt-orange: #e87a00;
  --misty-blue: #c3ceda;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  max-height: 100vh;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-header {
  width: 100%;
  max-width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: rgb(55, 58, 71);
  height: 60px;
  color: #fff;
  padding: 0 10px;
  z-index: 1005;
}

.main-header p {
  margin: 0;
  margin-right: 20px;
  padding: 0;
}

.app-wrapper {
  display: block;
  width: 100vw;
  min-height: calc(100vh - 120px);
  position: relative;
}

.app-inner {
  display: block;
  max-width: 100vh;
  min-height: 100vh;
  margin-top: 60px;
}

.main-content {
  margin: 15px;
  max-width: calc(100vw - 20px);
}

.sidemenu-wrapper {
  position: fixed;
  background-color: #ddd;
  border: 1px solid #333;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 0;
  opacity: 0;
  overflow: hidden;
  height: calc(100vh - 30px);
  background-color: #fff;
  transition: all 0.2s ease-in-out;
  padding-top: 40px;
  z-index: 1010;
}

.sidemenu-wrapper.open {
  opacity: 1;
  width: 220px;
  transition: all 0.2s ease-in-out;
}

.sidemenu-wrapper ul {
  margin: 0;
  padding: 0;
  width: 100%;
}

.sidemenu-wrapper ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  width: 100%;
}

.sidemenu-wrapper ul li.has-subnav > div {
  display: none;
}

.sidemenu-wrapper ul li.has-subnav.active,
.sidemenu-wrapper ul li.has-subnav.open > div {
  display: block;
}

.sidemenu-wrapper .subnav ul li a {
  font-size: 0.9rem;
  padding: 5px;
}

.sidemenu-wrapper ul li button {
  border: 0;
  background-color: transparent;
}

.sidemenu-wrapper ul li a,
.sidemenu-wrapper ul li button {
  display: flex;
  align-items: center;
  padding: 10px;
  font-weight: 600;
  color: black;
  width: 100%;
}
.sidemenu-wrapper ul li a.active,
.sidemenu-wrapper ul li button.active {
  color: var(--primary-color);
}
.sidemenu-wrapper ul li svg,
.sidemenu-wrapper ul li div svg {
  display: flex;
  font-size: 20px;
  margin-right: 20px;
}

.sidemenu-wrapper ul li a:hover,
.sidemenu-wrapper ul li button:hover {
  background-color: var(--baby-blue);
  border-radius: 5px;
  padding-left: 1rem;
}

.ui.container.reg-status {
  margin-top: 10px;
  margin-left: 0 !important;
  padding-left: 0 !important;
  width: 100vw !important;
}

.ui.container.reg-status table th + th {
  padding-left: 2rem;
}

.ui.container.reg-status table td {
  text-align: right;
}

.reg-status .grid-item {
  margin: 0;
  padding: 0;
  line-height: 0.8em !important;
}

.reg-status .grid {
  display: grid;
  margin: 20px;
  justify-content: start;
  grid-template-columns: 150px auto;
  grid-template-areas: 'grid-item-label grid-item-content';
}

.reg-status .grid-item {
  grid-area: 'grid-item-content';
}

.reg-status .grid-item.label {
  font-weight: bold;
  grid-area: 'grid-item-label';
}

.reg-status .grid-item-button {
  grid-column: 1 / span 2;
  justify-self: center;
  margin: 20px;
}

.ui.checkbox.checkbox-label-left {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ui.checkbox.checkbox-label-left input,
.ui.checkbox.checkbox-label-left ::before {
  left: auto;
  right: 0;
}

.ui.checkbox.checkbox-label-left.checked input,
.ui.checkbox.checkbox-label-left.checked ::after {
  left: auto;
  right: 0;
}

.ui.checkbox.checkbox-label-left label {
  padding-left: 0;
  padding-right: 1.875em;
}

.age-mismatch {
  font-weight: bold;
  color: #3f0000;
}

.scratch {
  font-weight: bold;
  color: rgb(115, 86, 5);
}

div.ui.search div.results {
  max-height: calc(100vh - 140px);
  overflow: auto;
}

div.event-results table th {
  text-align: left;
}

div.results-data {
  height: calc(100vh - 280px);
  overflow: auto;
  width: 100%;
}

body.printing .app-wrapper .sidemenu-wrapper,
body.printing .app-wrapper .main-header {
  display: none;
}

body.printing .app-wrapper .app-inner .results-data {
  height: 100%;
  overflow: visible;
}

body.printing .app-wrapper .app-inner {
  padding: 0;
  margin: 0;
  max-width: 7.5in;
}

body.printing .app-wrapper .app-inner table {
  width: 7.5in;
}

.ui.dimmer {
  z-index: 10000;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: url('/src/assets/img/kids-running-track.jpg') center center;
  background-size: cover;
  z-index: 20000;
}

.home-wrapper {
  background-color: rgba(27, 53, 109, 0.8);
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  z-index: 2000;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 20001;
}

.home-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 350px;
  max-width: 500px;
  margin: 2rem;
  padding: 2rem;
  background-color: #fff;
  z-index: 20002;
}

.home-login-form {
  margin-bottom: 3rem;
}

@media screen and (min-width: 700px) {
  .app-wrapper {
    display: block;
    position: relative;
  }

  .app-inner {
    position: relative;
    display: flex;
    max-width: 1000px;
    margin: 60px auto 0 auto;
  }

  .sidemenu-wrapper {
    top: -20px;
    position: relative;
    width: 220px;
    border: 0;
    border-right: 1px solid #ddd;
    opacity: 1;
  }

  .main-header {
    z-index: 1020;
  }
  #menuBars {
    display: none;
  }

  .main-content {
    width: calc(100% - 240px);
    display: block;
    padding: 20px 0 0 20px;
  }
}

@media screen and (min-width: 700px) {
  .app-wrapper {
    max-width: calc(100vw - 120px);
  }

  .main-content {
    max-width: calc(100vw - 200px);
  }
}
