@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
:root {
  --primary-color: #047aed;
  --secondary-color: #1c3fa8;
  --dark-color: #002240;
  --light-color: #f4f4f4;
  --success-color: #5cb85c;
  --error-color: #d9534f;
  --blue-gray: #738fa7;
  --midnight-blue: #0c4160;
  --dark-blue: #071330;
  --navy-blue: #05445e;
  --blue-grotto: #189ab4;
  --blue-green: #75e6da;
  --baby-blue: #d4f1f4;
  --desert-sun: #d89c60;
  --burnt-orange: #e87a00;
  --misty-blue: #c3ceda;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  max-height: 100vh;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-header {
  width: 100%;
  max-width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: rgb(55, 58, 71);
  height: 60px;
  color: #fff;
  padding: 0 10px;
  z-index: 1005;
}

.main-header p {
  margin: 0;
  margin-right: 20px;
  padding: 0;
}

.app-wrapper {
  display: block;
  width: 100vw;
  min-height: calc(100vh - 120px);
  position: relative;
}

.app-inner {
  display: block;
  max-width: 100vh;
  min-height: 100vh;
  margin-top: 60px;
}

.main-content {
  margin: 15px;
  max-width: calc(100vw - 20px);
}

.sidemenu-wrapper {
  position: fixed;
  background-color: #ddd;
  border: 1px solid #333;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 0;
  opacity: 0;
  overflow: hidden;
  height: calc(100vh - 30px);
  background-color: #fff;
  transition: all 0.2s ease-in-out;
  padding-top: 40px;
  z-index: 1010;
}

.sidemenu-wrapper.open {
  opacity: 1;
  width: 220px;
  transition: all 0.2s ease-in-out;
}

.sidemenu-wrapper ul {
  margin: 0;
  padding: 0;
  width: 100%;
}

.sidemenu-wrapper ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  width: 100%;
}

.sidemenu-wrapper ul li.has-subnav > div {
  display: none;
}

.sidemenu-wrapper ul li.has-subnav.active,
.sidemenu-wrapper ul li.has-subnav.open > div {
  display: block;
}

.sidemenu-wrapper .subnav ul li a {
  font-size: 0.9rem;
  padding: 5px;
}

.sidemenu-wrapper ul li button {
  border: 0;
  background-color: transparent;
}

.sidemenu-wrapper ul li a,
.sidemenu-wrapper ul li button {
  display: flex;
  align-items: center;
  padding: 10px;
  font-weight: 600;
  color: black;
  width: 100%;
}
.sidemenu-wrapper ul li a.active,
.sidemenu-wrapper ul li button.active {
  color: var(--primary-color);
}
.sidemenu-wrapper ul li svg,
.sidemenu-wrapper ul li div svg {
  display: flex;
  font-size: 20px;
  margin-right: 20px;
}

.sidemenu-wrapper ul li a:hover,
.sidemenu-wrapper ul li button:hover {
  background-color: var(--baby-blue);
  border-radius: 5px;
  padding-left: 1rem;
}

.ui.container.reg-status {
  margin-top: 10px;
  margin-left: 0 !important;
  padding-left: 0 !important;
  width: 100vw !important;
}

.ui.container.reg-status table th + th {
  padding-left: 2rem;
}

.ui.container.reg-status table td {
  text-align: right;
}

.reg-status .grid-item {
  margin: 0;
  padding: 0;
  line-height: 0.8em !important;
}

.reg-status .grid {
  display: grid;
  margin: 20px;
  justify-content: start;
  grid-template-columns: 150px auto;
  grid-template-areas: 'grid-item-label grid-item-content';
}

.reg-status .grid-item {
  grid-area: 'grid-item-content';
}

.reg-status .grid-item.label {
  font-weight: bold;
  grid-area: 'grid-item-label';
}

.reg-status .grid-item-button {
  grid-column: 1 / span 2;
  justify-self: center;
  margin: 20px;
}

.ui.checkbox.checkbox-label-left {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ui.checkbox.checkbox-label-left input,
.ui.checkbox.checkbox-label-left ::before {
  left: auto;
  right: 0;
}

.ui.checkbox.checkbox-label-left.checked input,
.ui.checkbox.checkbox-label-left.checked ::after {
  left: auto;
  right: 0;
}

.ui.checkbox.checkbox-label-left label {
  padding-left: 0;
  padding-right: 1.875em;
}

.age-mismatch {
  font-weight: bold;
  color: #3f0000;
}

.scratch {
  font-weight: bold;
  color: rgb(115, 86, 5);
}

div.ui.search div.results {
  max-height: calc(100vh - 140px);
  overflow: auto;
}

div.event-results table th {
  text-align: left;
}

div.results-data {
  height: calc(100vh - 280px);
  overflow: auto;
  width: 100%;
}

body.printing .app-wrapper .sidemenu-wrapper,
body.printing .app-wrapper .main-header {
  display: none;
}

body.printing .app-wrapper .app-inner .results-data {
  height: 100%;
  overflow: visible;
}

body.printing .app-wrapper .app-inner {
  padding: 0;
  margin: 0;
  max-width: 7.5in;
}

body.printing .app-wrapper .app-inner table {
  width: 7.5in;
}

.ui.dimmer {
  z-index: 10000;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: url(/static/media/kids-running-track.e330ff0b.jpg) center center;
  background-size: cover;
  z-index: 20000;
}

.home-wrapper {
  background-color: rgba(27, 53, 109, 0.8);
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  z-index: 2000;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 20001;
}

.home-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 350px;
  max-width: 500px;
  margin: 2rem;
  padding: 2rem;
  background-color: #fff;
  z-index: 20002;
}

.home-login-form {
  margin-bottom: 3rem;
}

@media screen and (min-width: 700px) {
  .app-wrapper {
    display: block;
    position: relative;
  }

  .app-inner {
    position: relative;
    display: flex;
    max-width: 1000px;
    margin: 60px auto 0 auto;
  }

  .sidemenu-wrapper {
    top: -20px;
    position: relative;
    width: 220px;
    border: 0;
    border-right: 1px solid #ddd;
    opacity: 1;
  }

  .main-header {
    z-index: 1020;
  }
  #menuBars {
    display: none;
  }

  .main-content {
    width: calc(100% - 240px);
    display: block;
    padding: 20px 0 0 20px;
  }
}

@media screen and (min-width: 700px) {
  .app-wrapper {
    max-width: calc(100vw - 120px);
  }

  .main-content {
    max-width: calc(100vw - 200px);
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  overflow: auto;
}

.card {
  background-color: #fff;
  color: #333;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 10px;
}

.btn {
  display: inline-block;
  padding: 10px 30px;
  cursor: pointer;
  background: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 5px;
}

.btn-outline {
  background-color: transparent;
  border: 1px #fff solid;
}

.btn:hover {
  transform: scale(0.98);
}

/* Backgrounds & colored buttons */
.bg-primary,
.btn-primary {
  background-color: var(--primary-color);
  color: #fff;
}

.bg-secondary,
.btn-secondary {
  background-color: var(--secondary-color);
  color: #fff;
}

.bg-dark,
.btn-dark {
  background-color: var(--dark-color);
  color: #fff;
}

.bg-light,
.btn-light {
  background-color: var(--light-color);
  color: #333;
}

.bg-primary a,
.btn-primary a,
.bg-secondary a,
.btn-secondary a,
.bg-dark a,
.btn-dark a {
  color: #fff;
}

/* Text colors */
.text-primary {
  color: var(--primary-color);
}

.text-secondary {
  color: var(--secondary-color);
}

.text-dark {
  color: var(--dark-color);
}

.text-light {
  color: var(--light-color);
}

/* Text sizes */
.lead {
  font-size: 20px;
}

.sm {
  font-size: 1rem;
}

.md {
  font-size: 2rem;
}

.lg {
  font-size: 3rem;
}

.xl {
  font-size: 4rem;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

/* Alert */
.alert {
  background-color: var(--light-color);
  padding: 10px 20px;
  font-weight: bold;
  margin: 15px 0;
}

.alert i {
  margin-right: 10px;
}

.alert-success {
  background-color: var(--success-color);
  color: #fff;
}

.alert-error {
  background-color: var(--error-color);
  color: #fff;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  gap: 20px;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.grid-3 {
  grid-template-columns: repeat(3, 1fr);
}

.inline-block {
  display: inline-block;
}

/* Margin */
.my-1 {
  margin: 1rem 0 !important;
}

.my-2 {
  margin: 1.5rem 0 !important;
}

.my-3 {
  margin: 2rem 0 !important;
}

.my-4 {
  margin: 3rem 0 !important;
}

.my-5 {
  margin: 4rem 0 !important;
}

.mx-1 {
  margin: 0 1rem !important;
}

.mx-2 {
  margin: 0 1.5rem !important;
}

.mx-3 {
  margin: 0 2rem !important;
}

.mx-4 {
  margin: 0 3rem !important;
}

.mx-5 {
  margin: 0 4rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mr-2 {
  margin-right: 1.5rem !important;
}

.mr-3 {
  margin-right: 2rem !important;
}

.mr-4 {
  margin-right: 3rem !important;
}

.mr-5 {
  margin-right: 4rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.ml-2 {
  margin-left: 1.5rem !important;
}

.ml-3 {
  margin-left: 2rem !important;
}

.ml-4 {
  margin-left: 3rem !important;
}

.ml-5 {
  margin-left: 4rem !important;
}

.m-1 {
  margin: 1rem !important;
}

.m-2 {
  margin: 1.5rem !important;
}

.m-3 {
  margin: 2rem !important;
}

.m-4 {
  margin: 3rem !important;
}

.m-5 {
  margin: 4rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 1.5rem !important;
}

.mb-3 {
  margin-bottom: 2rem !important;
}

.mb-4 {
  margin-bottom: 3rem !important;
}

.mb-5 {
  margin-bottom: 4rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-2 {
  margin-top: 1.5rem !important;
}

.mt-3 {
  margin-top: 2rem !important;
}

.mt-4 {
  margin-top: 3rem !important;
}

.mt-5 {
  margin-top: 4rem !important;
}

/* Padding */
.py-1 {
  padding: 1rem 0 !important;
}

.py-2 {
  padding: 1.5rem 0 !important;
}

.py-3 {
  padding: 2rem 0 !important;
}

.py-4 {
  padding: 3rem 0 !important;
}

.py-5 {
  padding: 4rem 0 !important;
}

.px-1 {
  padding: 0 1rem !important;
}

.px-2 {
  padding: 0 1.5rem !important;
}

.px-3 {
  padding: 0 2rem !important;
}

.px-4 {
  padding: 0 3rem !important;
}

.px-5 {
  padding: 0 4rem !important;
}

.p-1 {
  padding: 1rem !important;
}

.p-2 {
  padding: 1.5rem !important;
}

.p-3 {
  padding: 2rem !important;
}

.p-4 {
  padding: 3rem !important;
}

.p-5 {
  padding: 4rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.pb-2 {
  padding-bottom: 1.5rem !important;
}

.pb-3 {
  padding-bottom: 2rem !important;
}

.pb-4 {
  padding-bottom: 3rem !important;
}

.pb-5 {
  padding-bottom: 4rem !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pt-2 {
  padding-top: 1.5rem !important;
}

.pt-3 {
  padding-top: 2rem !important;
}

.pt-4 {
  padding-top: 3rem !important;
}

.pt-5 {
  padding-top: 4rem !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.pr-2 {
  padding-right: 1.5rem !important;
}

.pr-3 {
  padding-right: 2rem !important;
}

.pr-4 {
  padding-right: 3rem !important;
}

.pr-5 {
  padding-right: 4rem !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.pl-2 {
  padding-left: 1.5rem !important;
}

.pl-3 {
  padding-left: 2rem !important;
}

.pl-4 {
  padding-left: 3rem !important;
}

.pl-5 {
  padding-left: 4rem !important;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

.bolder {
  font-weight: 600;
}

